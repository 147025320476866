<template>
	<div v-show="isInit">
		<div class="contract_container">
			<div class="top">{{ navigatorTitle }}</div>
			<div class="container">
				<div id="zoomBox">
					<div class="contract_main">
						<div class="tips">*请在合同结尾处签字</div>
						<div class="contract_title">产品销售合同</div>
						<table border="0" cellpadding="0" cellspacing="0" class="mytable">
							<tr v-if="customerInfo && userInfo">
								<td width="12%">
									<div class="b_in">
										<span>买</span><span>方</span><span>名</span><span>称</span>
									</div>
								</td>
								<td width="38%">{{ customerInfo.departName }}</td>
								<td width="12%">
									<div class="b_in">
										<span>卖</span><span>方</span><span>名</span><span>称</span>
									</div>
								</td>
								<td width="38%" class="noborder">{{ userInfo.departName }}</td>
							</tr>
							<tr v-if="customerInfo && userInfo">
								<td>
									<div class="b_in">
										<span>地</span><span>址</span>
									</div>
								</td>
								<td>{{ customerInfo.address }}</td>
								<td>
									<div class="b_in">
										<span>地</span><span>址</span>
									</div>
								</td>
								<td class="noborder">{{ userInfo.address }}</td>
							</tr>
							<tr v-if="customerInfo && userInfo">
								<td>
									<div class="b_in">
										<span>联</span><span>系</span><span>人</span>
									</div>
								</td>
								<td>{{ customerInfo.customerName }}</td>
								<td>
									<div class="b_in">
										<span>联</span><span>系</span><span>人</span>
									</div>
								</td>
								<td class="noborder">{{ userInfo.realname }}</td>
							</tr>
							<tr v-if="customerInfo && userInfo">
								<td>
									<div class="b_in">
										<span>电</span><span>话</span>
									</div>
								</td>
								<td>{{ customerInfo.phone }}</td>
								<td>
									<div class="b_in">
										<span>电</span><span>话</span>
									</div>
								</td>
								<td class="noborder">{{ userInfo.phone }}</td>
							</tr>
							<tr v-if="customerInfo && userInfo">
								<td>
									<div class="b_in">
										<span>开</span><span>户</span><span>行</span>
									</div>
								</td>
								<td>{{ customerInfo.openingBank }}</td>
								<td>
									<div class="b_in">
										<span>开</span><span>户</span><span>行</span>
									</div>
								</td>
								<td class="noborder">{{ userInfo.openingBank }}</td>
							</tr>
							<tr v-if="customerInfo && userInfo">
								<td>
									<div class="b_in">
										<span>账</span><span>号</span>
									</div>
								</td>
								<td>{{ customerInfo.bankNumber }}</td>
								<td>
									<div class="b_in">
										<span>账</span><span>号</span>
									</div>
								</td>
								<td class="noborder">{{ userInfo.bankNumber }}</td>
							</tr>
							<tr v-if="customerInfo && userInfo">
								<td>
									<div class="b_in">
										<span>税</span><span>号</span>
									</div>
								</td>
								<td>{{ customerInfo.dutyParagraph }}</td>
								<td>
									<div class="b_in">
										<span>税</span><span>号</span>
									</div>
								</td>
								<td class="noborder">{{ userInfo.dutyParagraph }}</td>
							</tr>
							<tr>
								<td colspan="4" class="top_line">
									买、卖双方根据《中华人民共和国合同法》及《经济法》相关法律规定，经友好协商，签订本合同。
								</td>
							</tr>
						</table>

						<div class="project_info" v-if="orderAddressInfo">
							<div class="title">项目信息</div>
							<div class="project_main">
								<div>
									项目名称：{{ orderAddressInfo.projectName }}<br />
									送货地址：{{ orderAddressInfo.provinceName }}{{ orderAddressInfo.cityName }}{{ orderAddressInfo.districtName }}
									{{ orderAddressInfo.address }}
								</div>
								<div>
									收货人：{{ orderAddressInfo.uaRealName }}<br />
									联系电话：{{ orderAddressInfo.uaMobile }}
								</div>
							</div>
						</div>


						<div class="product_info">
							<table border="0" cellpadding="0" cellspacing="0" class="mytable1">
								<tr>
									<td width="24%" class="table_bg">产品名称</td>
									<td width="24%" class="table_bg">规格型号</td>
									<td width="10%" class="table_bg">单位</td>
									<td width="10%" class="table_bg">数量</td>
									<td width="16%" class="table_bg">单件价￥</td>
									<td width="16%" class="table_bg">小计￥</td>
								</tr>
								<tr v-for="(item, index) in productList" :key="index">
									<td>{{ item.productName }}</td>
									<template v-if="item.proType == 3 || item.proType == 1">
										<!-- 自定义商品 成品件 -->
										<td>{{ item.standardsName }}</td>
									</template>
									<template v-if="item.proType == 2">
										<!-- 加工件 -->
										<td>{{ handerNodeValueList(item.nodeValueList) }}</td>
									</template>
									<td>{{ item.unitName }}</td>
									<td>{{ item.orderNum }}</td>
									<td>{{ item.unitPrice }}</td>
									<td>{{ item.totalAmount.toFixed(2) }}</td>
								</tr>
								<tr class="table_foot">
									<td colspan="3" style="text-align: left;padding-left: 10px;"><span class="txt">合计：</span></td>
									<td><span class="txt">{{ totalCount }}</span></td>
									<td colspan="2" style="text-align: right;padding-right: 10px;;"><span class="txt">￥{{ totalPrice && totalPrice.toFixed(2) }}</span>
									</td>
								</tr>
								<tr>
									<td colspan="6" class="table_bg">
										<div class="total_price">
											<span>合计人民币（大写）：</span>
											<span class="f_bold">{{ upperCaseTotalPrice }}</span>
										</div>
									</td>
								</tr>
							</table>
						</div>

						<div class="agree_info">
							<div v-html="protocolContent" class="rich-txt"></div>
						</div>
					</div>
					<div class="sign_box">
						<div class="sign_item">
							<div class="bt">卖方签字</div>
							<div class="sign_content">
								<img :src="contractDetail.saleSignImg" class="img" v-if="contractDetail && contractDetail.saleSignImg" />
							</div>
							<div class="sign_tip" v-if="contractDetail && !contractDetail.saleSignImg">待签字</div>
							<div class="signed" v-if="contractDetail && contractDetail.saleSignImg">{{ contractDetail.saleSignTime }}
								签署</div>
							<template v-if="contractDetail && contractDetail.sealImg">
								<img :src="sealImg" class="zhang"/>
							</template>
						</div>
						<div class="sign_item" @click="sign()">
							<div class="bt">买方签字</div>
							<div class="sign_content">
								<img :src="contractDetail.buySignImg" class="img" v-if="contractDetail && contractDetail.buySignImg" />
							</div>
							<div class="sign_tip" v-if="contractDetail && !contractDetail.buySignImg">待签字<br />请在确认卖方签字后再签</div>
							<div class="signed" v-if="contractDetail && contractDetail.buySignImg">{{ contractDetail.buySignTime }}
								签署</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<Autograph @signAutoGraph="signAutoGraph" ref="autoGraph" :hasPass="false" :hasTips="true">
					</Autograph>
	</div>
</template>

<script>
import axios from "axios"
import {
	// getParam,
	getQueryString,
	convertCurrency,
	base64toFile,
	// dataURLtoBlob
} from "@/common/comm.js"
import $http from "@/common/api/request.js"
import Autograph from '@/components/Autograph.vue'
import { EasyScroller } from 'easyscroller'
export default {
	name: "contract",
	data() {
		return {
			isInit: true,
			from: "",
			statusBarHeight: 0,
			navigatorBarHeight: 0,
			topH: 0,
			status: null,
			navigatorTitle: "",
			isShowMenu: false,
			sellerIsSign: false, // 卖方是否签字
			salt: "",
			ordId: "",
			contId: "",
			forwardId: null,
			contractStatus: "",
			contractDetail: null,
			customerInfo: null,
			orderAddressInfo: null,
			userInfo: null,
			productList: [],
			totalCount: 0,
			totalPrice: 0,
			upperCaseTotalPrice: "",
			protocolContent: "",
			isShowProtocolDialog: false,
			saleSignImg: "",
			saleSignTime: "",
			buySignImg: "",
			buySignTime: "",
			sealImg: "",
			scroller: null
		}
	},
	components: {
		Autograph
	},
	mounted() {
		let request = getQueryString()
		this.ordId = request['id']
		this.salt = request['salt']
		this.contId = request['contId']
		this.getContractDetail()
		this.setScroller()
	},
	beforeDestroy() {
		if (this.scroller) this.scroller.destroy()
	},
	methods: {
		getContractDetail() {
			let params = {
				url: `/sys/order/preview?id=${this.ordId}&salt=${this.salt}`,
				method: 'GET',
				header: {
					'Content-Type': 'application/json'
				}
			}
			$http.request(params).then((res) => {
				if (res.code == 200) {
					this.contractDetail = res.result
					this.customerInfo = res.result.customerInfoVO
					this.userInfo = res.result.userInfoVO
					this.orderAddressInfo = res.result.orderAddressVO
					this.productList = this.handerProduct(res.result.orderProductVOList)
					this.protocolContent = res.result.protocolContent
					this.contractStatus = res.result.contractStatus
					this.sealImg = res.result.sealImg
					this.calcCountAndPrice()
					this.upperCaseTotalPrice = convertCurrency(this.totalPrice)
					this.navigatorTitle = '合同 ' + res.result.contractNo
					document.title = this.navigatorTitle
				}
				this.isInit = true
			}).catch(() => { })
		},
		transferContract() {
			if (this.contractDetail && !this.contractDetail.saleSignImg) {

				return
			}
		},
		handerProduct(orderProductVOList) {
			let tempArr = []
			orderProductVOList = orderProductVOList.filter((pro) => pro.isAttachProd == 0)
			orderProductVOList.forEach((product) => {
				return product.orderItemVOList.forEach((orderItem) => {
					tempArr.push({
						...orderItem,
						unitName: product.unitName,
						productName: product.productName,
						proType: product.proType,
						totalAmount: orderItem.orderNum * orderItem.unitPrice
					})
				})
			})
			return tempArr
		},
		handerNodeValueList(nodeValueList) {
			let nodeStr = ""
			nodeValueList.forEach((item, index) => {
				if (index == nodeValueList.length - 1) {
					nodeStr += item.nodeValue
				} else {
					nodeStr += item.nodeValue + '*'
				}
			})
			return nodeStr
		},
		setScroller() {
			const ele = document.querySelector('#zoomBox'); //缩放盒子
			this.scroller = new EasyScroller(ele, {
				scrollingX: true,
				scrollingY: true,
				zooming: true,
				minZoom: 1,    //最小缩放
				maxZoom: 4,    //最大缩放
				zoomLevel: 1, //初始值缩放
				bouncing: false
			});
		},
		calcCountAndPrice() {
			this.totalCount = 0
			this.totalPrice = 0
			this.productList.forEach((item) => {
				this.totalCount += item.orderNum
				this.totalPrice += item.totalAmount
			})
		},
		sign() {
			if (this.contractDetail && this.contractDetail.buySignImg) {
				return
			}
			this.$refs.autoGraph.show()
		},
		signAutoGraph(signBase64Path) {
			let _this = this
			let file = base64toFile(signBase64Path, new Date().getTime() + Math.random() + ".jpg")
			const formData = new FormData();
			formData.append("file", file)
			axios({
				method: "post",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				url: "https://fengyisuan.com/app/sys/common/upload",
				data: formData,
			})
				.then(function (res) {
					if (res.data.code == 200) {
						_this.contractSignature(res.data.result)
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		// 合同签字
		contractSignature(signImgUrl) {
			let _this = this
			let params = {
				url: '/sys/order/buySignature',
				method: 'POST',
				header: {
					'Content-Type': 'application/json'
				},
				data: {
					forwardId: this.ordId,
					signImgUrl,
					id: this.contractDetail.id
				}
			}
			$http.request(params).then((res) => {
				if (res.code == 200) {
					_this.$refs.autoGraph.hide()
					_this.getContractDetail()
				}
			}).catch(() => { })
		},
		showMenus() {
			this.isShowMenu = true
		},
		hideMenus() {
			this.isShowMenu = false
		},
		deleteContract() {
			this.hideMenus()
			this.$refs.popup.show()
		},
		conformDelContract() {
			let params = {
				url: '/sys/order/removeContract?contId=' + this.contId,
				method: 'POST',
				header: {
					'Content-Type': 'application/json',
					'X-Access-Token': this.token
				}
			}
			$http.request(params).then((res) => {
				if (res.code == 200) {
					// this.goBack()
				}
				this.hidePopup()
			}).catch(() => { })
		},
		hideEditor() {
			this.isShowProtocolDialog = false
		},
		hidePopup() {
			this.$refs.popup.hide()
		}
	}
}
</script>
<style>
body {
	background-color: #f5f5f5;
	padding: 0;
	margin: 0;
}
</style>
<style scoped>
.contract_container {
	padding-top: 44px;
}

.no-pad {
	padding-bottom: 0 !important;
}
.container {
	height: calc(100vh - 44px);
	overflow: hidden;
}
.top {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 10;
	background-color: #fff;
	width: 100%;
	height: 44px;
	text-align: center;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.status-bar {
	width: 100%;
}

.navgator-bar {
	width: 100%;
	padding: 0 18rpx;
	box-sizing: border-box;
	/* background-color: #0072DC; */
	color: #242424;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.menus {
	width: 222rpx;
	position: absolute;
	right: 20rpx;
	top: 0;
	z-index: 20;
}

.menus .m_unit {
	width: 222rpx;
	height: 98rpx;
	font-size: 30rpx;
	color: #000;
	background-color: #fff;
	text-align: center;
	line-height: 98rpx;
	margin-bottom: 10rpx;
}

.menus .m_unit:last-child {
	margin-bottom: 0;
}

.mask {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .5);
}

.contract_main {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	margin-top: 10px;
}

.tips {
	text-align: center;
	font-size: 11px;
	color: #ff0000;
	padding: 15px 0 15px 0;
	border-bottom: 1px solid #9F9F9F;
}

.contract_title {
	font-size: 9px;
	font-weight: bold;
	text-align: center;
	padding: 8px 0;
}

.mytable {
	width: 100%;
	border-top: 1px solid #9F9F9F;
	border-bottom: 1px solid #9F9F9F;
}

.mytable td {
	font-size: 6px;
	border-right: 1px solid #9F9F9F;
	padding: 5px;
	vertical-align: top;
}

.top_line {
	border-top: 1px solid #9F9F9F;
}

.mytable .b_in {
	display: flex;
	justify-content: space-between;
}

.mytable .noborder {
	border: 0;
}

.project_info {
	display: flex;
	flex-direction: column;
	padding: 7px 8px;
}

.project_info .title {
	font-size: 9px;
	font-weight: bold;
	margin-bottom: 9px;
}

.project_main {
	display: flex;
	justify-content: space-between;
	font-size: 6px;
}

.mytable1 {
	width: 100%;
	border-top: 1px solid #9F9F9F;
	/* border-bottom: 1px solid #9F9F9F; */
}

.mytable1 td {
	font-size: 6px;
	border-bottom: 1px solid #9F9F9F;
	padding: 5px;
	vertical-align: top;
	text-align: center;
}

.table_bg {
	background-color: #F6F6F6;
}

.f_bold {
	font-weight: bold;
}

.total_price {
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
}

.agree_info {
	font-size: 12px;
	padding: 10px 10px;
	line-height: 20px;
}

.rich-txt {
	word-break: break-all;
	white-space: pre-line;
}

.sign_box {
	background-color: #fff;
	margin-top: 10px;
	padding: 20px 10px 20px 10px;
	display: flex;
	justify-content: space-between;
}

.sign_item {
	width: 172px;
	font-size: 13px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.sign_item .zhang {
	width: 109px;
	height: 109px;
	position: absolute;
	right: 10px;
	bottom: 0;
}

.sign_item .bt {
	text-align: center;
	margin-bottom: 12px;
}

.sign_item .sign_tip {
	text-align: center;
	color: #FF0000;
	margin-top: 14px;
}

.sign_item .signed {
	text-align: center;
	color: #000;
	margin-top: 14px;
}

.sign_content {
	width: 172px;
	height: 110px;
	background: #F5F5F5;
	border: 1px solid #A3A3A3;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sign_content .img {
	width: 88px;
	height: 67px;
}

.foot {
	width: 100%;
	height: 49px;
	background-color: #fff;
	position: fixed;
	display: flex;
	left: 0;
	bottom: 0;
}

.foot .f_u {
	flex: 1;
	height: 49px;
	text-align: center;
	line-height: 49px;
	font-size: 15px;
}

.foot .f_u.grey {
	background-color: #848484;
	color: #fff;
}

.foot .f_u.transfer {
	background-color: #2BA246;
	color: #fff;
}

.txtinfo {
	font-size: 15px;
}

.ft {
	width: 100%;
	display: flex;
}

.table_foot td {
	background-color: #F6F6F6;
	padding: 10px 0;
}

.table_foot .txt {
	font-weight: bolder;
}

.ft_u {
	flex: 1;
	height: 39px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	border-right: 1px solid #EBEBEB;
}

.ft_u:last-child {
	border-right: 0;
}

.placeorder {
	background-color: #F83144;
	color: #fff;
}

.ft_u.cancel {
	color: #F83345;
}

.mark {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .5);
	z-index: 100;
}
</style>